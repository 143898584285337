<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.teamId ? 'View/Edit' : 'Create'} Team`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name" :invalid-feedback="getValidationMessage('name')" :state="!validationErrors.name">
                <b-form-input type="text" v-model="editedTeam.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description" :invalid-feedback="getValidationMessage('description')" :state="!validationErrors.description">
                <b-form-textarea v-model="editedTeam.description" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <h2>Members</h2>
              <user-search class="mb-3" @delete="rmCleaner(ind)" show-delete type="cleaner" v-model="cleaner.user_id" :key="ind" v-for="(cleaner, ind) in editedTeam.members" />
              <b-button block @click.prevent="addCleaner" variant="primary"><fa-icon icon="user-plus" /> Add Cleaner</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveUser" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.teamId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserSearch from '../../components/UserSearch'
import handleErrors from '../../mixins/handleErrors'

const defaultTeam = {
  name: '',
  description: '',
  members: [{
    id: null,
  }],
}

export default {
  components: { UserSearch },
  emits: ['hidden'],
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedTeam: defaultTeam,
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    team: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.teamId && this.team) {
      this.loading = true;

      try {
        const team = await this.fetchSingle(this.$route.params.teamId);
        this.editedTeam = {
          members: [{
            id: null,
          }],
          ...team,
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load team. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('teams', ['fetch', 'fetchSingle', 'updateOrCreate']),
    addCleaner() {
      this.editedTeam.members.push({
        id: null,
      });
    },
    rmCleaner(ind) {
      this.editedTeam.members.splice(ind, 1);
    },
    closeModal() {
      this.editedTeam = defaultTeam;
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    saveUser() {
      this.saving = true;
      this.updateOrCreate(this.editedTeam).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    team(value) {
      this.editedTeam = {
        members: [{
          id: null,
        }],
        ...value,
      };
    }
  }
}
</script>

<style>
.modal-body-col {
  height: 75vh;
  overflow: auto;
}
</style>
